import pathDefaultConfig from './PathDefaultConfig'
import axiosIns from '@/libs/axios'

class PathServices {
    config = { ...pathDefaultConfig }

    axios = null

    constructor() {
        this.axios = axiosIns
    }

    async handleEdit() {
        const result = await this.axios.get(`${this.config.editEndpoint}`)

        return result
    }

    async handleUpdate(data) {
        const result = await this.axios.post(`${this.config.updateEndpoint}`, data)

        return result
    }
}

export default new PathServices()
