<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          class="mb-2"
          lg="12"
          md="12"
          sm="12"
        >
          <h5><strong>NOTA:</strong> <small>La url asignada se puede acceder de modo publico y es para crear tickets, el path no puede llevar acento ni caracteres especiales solo letras y numeros.</small></h5>
        </b-col>
      </b-row>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label>Path</label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="path"
              rules="required"
            >
              <b-input-group prepend="https://app.xcloudcr.com/company/tickets/">
                <b-form-input
                  v-model="path"
                  class="form-control-merge"
                  name="path"
                  placeholder="Path"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="accessPermissions('users.change.path')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'main' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import PathServices from '../services/PathServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BForm,
  },
  data() {
    return {
      path: '',
      required,
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.path = ''
    },
    async onHandleEdit() {
      if (this.accessPermissions('company.path')) {
        await PathServices.handleEdit().then(response => {
          this.path = response.data.data.path
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleValidationForm() {
      if (this.accessPermissions('users.change.path')) {
        await this.$refs.formValidation.validate().then(success => {
          if (success) {
            PathServices.handleUpdate({ path: this.path }).then(response => {
              if (response.data.data) {
                this.clearForm()
                this.$refs.formValidation.reset()
                this.swal('El path ha sido actualizado correctamente', 'success')
                this.onHandleEdit()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
